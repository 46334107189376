import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['touchForm', 'tagForm', 'tagLink', 'tagInput']

  connect() {
    
  }
  
  showTouchForm() { 
    console.log("show it")
    this.touchFormTarget.classList.remove('hidden')
  }
  
  showTagForm(e) { 
    e.preventDefault()
    console.log("show it")
    this.tagLinkTarget.classList.add('hidden')
    this.tagFormTarget.classList.remove('hidden')
    this.tagInputTarget.focus()
  }
}
